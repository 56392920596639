import {
  Avatar,
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Alert,
  Link as MuiLink,
  Tooltip,
  IconButton
} from '@mui/material';
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  Timestamp
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { firestore } from '../firebase';
import ReadMore from '../components/ReadMore'; // Reuse ReadMore component
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const EventDetail = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [eventCalls, setEventCalls] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // 1) Fetch Event details
    const fetchEvent = async () => {
      try {
        const docRef = doc(firestore, 'events', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const eventData = docSnap.data();
          // Deserialize schedules if necessary
          if (eventData.schedules) {
            eventData.schedules = JSON.parse(eventData.schedules);
          }
          setEvent(eventData);
        } else {
          console.log('No such document!');
        }
      } catch (err) {
        console.error('Error fetching event:', err);
        setError(err.message);
      }
    };

    // 2) Fetch all calls for this event
    const fetchEventCalls = async () => {
      try {
        const callsCollectionRef = collection(firestore, 'calls');
        const callsQueryRef = query(
          callsCollectionRef,
          where('endDate', '>=', Timestamp.fromDate(new Date())),
          where('eventId', '==', id)
        );
        const callsSnapshot = await getDocs(callsQueryRef);
        setEventCalls(
          callsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      } catch (err) {
        console.error('Error fetching event calls:', err);
        setError(err.message);
      }
    };

    fetchEvent();
    fetchEventCalls();
  }, [id]);

  if (error) {
    return (
      <Container sx={{ mt: 4, textAlign: 'center' }}>
        <Typography color="error">Error: {error}</Typography>
      </Container>
    );
  }

  if (!event) {
    return (
      <Container sx={{ mt: 4, textAlign: 'center' }}>
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  // (A) Create a tooltip text for session type disclaimers
  const sessionTypeDisclaimer = {
    regional: 'This is a Regional Session. Selected participants may be invited to future events. Learn more at our "About us" page.',
    national: 'This is a National Selection Conference. Selected participants may represent at higher-level sessions. Learn more at our "About us" page.',
    mini: 'This is a Mini Session, a one-day event. Learn more at our "About us" page.',
    other: ''
  };

  const sessionTypeLabel = event.sessionType || 'Unknown';
  const tooltipText = sessionTypeDisclaimer[event.sessionType] || '';

  // (B) Separate delegate call from other calls
  const delegateCall = eventCalls.find((call) => call.isDelegateCall === true);
  const otherCalls = eventCalls.filter((call) => !call.isDelegateCall);

  // Helper to format Firestore Timestamps
  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleDateString();
  };

  return (
    <Container sx={{ mb: 6 }}>
      {/* Header Image & Logo */}
      <Card sx={{ boxShadow: 0 }}>
        <CardMedia
          component="img"
          height="300"
          image={event.headerImageUrl}
          alt={event.title}
        />
        {event.logoUrl && (
          <Box
            component="img"
            src={event.logoUrl}
            alt={event.title}
            sx={{
              position: 'relative',
              top: '-100px',
              margin: '0 auto',
              display: 'block',
              height: '200px',
              objectFit: 'contain',
              zIndex: 1
            }}
          />
        )}
      </Card>

      {/* Title & Date */}
      <Box textAlign="center" mt={-8}>
        <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 1 }}>
          {event.title}
        </Typography>
        <Typography variant="h6">
          {formatDate(event.startDate)} - {formatDate(event.endDate)}
        </Typography>

        {/* (C) Session Type in a tooltip */}
        {event.sessionType && tooltipText && (
          <Box display="inline-flex" alignItems="center" justifyContent="center" mt={2}>
            <Typography variant="body1" sx={{ mr: 1 }}>
              Session Type: <strong>{sessionTypeLabel}</strong>
            </Typography>
            <Tooltip title={tooltipText}>
              <IconButton size="small">
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      {/* Description */}
      {event.description && (
        <Box mt={4} mb={6} textAlign="left" maxWidth="md" mx="auto">
          <Typography
            variant="body1"
            gutterBottom
            dangerouslySetInnerHTML={{ __html: event.description }}
          />

          {/* (D) Highlighted "delegates call" if it exists */}
          {delegateCall && (
            <Box mt={4}>
              <Alert
                severity="info"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    href={delegateCall.applyLink}
                    target="_blank"
                  >
                    Sign Up
                  </Button>
                }
              >
                <Typography variant="body1">
                  <strong>Delegate Call:</strong> {delegateCall.title}
                  <ReadMore text={delegateCall.description} limit={150} />
                </Typography>
              </Alert>
            </Box>
          )}
        </Box>
      )}

      {/* Partners */}
      {event.partners && event.partners.length > 0 && (
        <Box
          sx={{
            py: 6,
            mb: 8,
            backgroundColor: '#f0f8ff',
            borderRadius: 3,
            textAlign: 'center'
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4 }}>
            Supported By
          </Typography>
          <Grid container spacing={4} justifyContent="center" alignItems="center">
            {event.partners.map((partner, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={event.partners.length === 1 ? 12 : 4}
                key={index}
              >
                <Box sx={{ p: 2 }}>
                  <Box
                    component="img"
                    src={partner.logoUrl}
                    alt={partner.name}
                    sx={{
                      maxWidth: '100%',
                      maxHeight: '150px',
                      objectFit: 'contain',
                      mb: 2,
                      transition: 'transform 0.2s',
                      '&:hover': { transform: 'scale(1.05)' }
                    }}
                  />
                  <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
                    {partner.name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* People Involved */}
      {event.contacts && event.contacts.length > 0 && (
        <Box mb={8}>
          <Box textAlign="center" mb={4}>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              Our Team
            </Typography>
          </Box>
          <Grid container spacing={4}>
            {event.contacts.map((contact, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper
                  elevation={2}
                  sx={{ p: 4, borderRadius: 3, textAlign: 'center' }}
                >
                  <Avatar
                    src={contact.imageUrl}
                    sx={{ width: 100, height: 100, mx: 'auto', mb: 2 }}
                    alt={contact.name}
                  />
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {contact.name}
                  </Typography>
                  {contact.role && (
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      sx={{ fontWeight: 'medium', mb: 1 }}
                    >
                      {contact.role}
                    </Typography>
                  )}
                  {contact.email && (
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 0.5 }}>
                      {contact.email}
                    </Typography>
                  )}
                  {contact.phone && (
                    <Typography variant="body2" color="textSecondary">
                      {contact.phone}
                    </Typography>
                  )}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* Schedules */}
      {event.schedules && event.schedules.length > 0 && (
        <Box mb={6}>
          {event.schedules.map((schedule, index) => (
            <Box key={index} mb={4}>
              {schedule.scheduleName && (
                <Box mb={2}>
                  <Typography variant="h5" sx={{ fontWeight: 'medium', color: 'text.primary' }}>
                    {schedule.scheduleName}
                  </Typography>
                  <Divider sx={{ mb: 2 }} />
                </Box>
              )}
              <Paper
                elevation={0}
                sx={{ border: '1px solid #eee', borderRadius: 2, overflowX: 'auto' }}
              >
                <Table sx={{ minWidth: 600 }}>
                  <TableHead>
                    <TableRow>
                      {schedule.columns.map((column, colIndex) => (
                        <TableCell
                          key={colIndex}
                          sx={{ backgroundColor: '#f7f7f7', fontWeight: 'medium' }}
                        >
                          {column}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {schedule.rows.map((row, rowIndex) => (
                      <TableRow key={rowIndex} hover>
                        {schedule.columns.map((column, colIndex) => (
                          <TableCell
                            key={colIndex}
                            sx={{ borderBottom: '1px solid #f0f0f0' }}
                          >
                            {row[column] || '-'}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Box>
          ))}
        </Box>
      )}

      {/* (E) "Open Calls" section for non-delegate calls */}
      {otherCalls.length > 0 && (
        <Box mb={8}>
          <Box textAlign="center" mb={4}>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              Open Calls
            </Typography>
          </Box>
          <Grid container spacing={4}>
            {otherCalls.map((call) => (
              <Grid item xs={12} sm={6} md={4} key={call.id}>
                <Paper
                  elevation={2}
                  sx={{ p: 4, borderRadius: 3, display: 'flex', flexDirection: 'column', height: '100%' }}
                >
                  <Typography variant="h6" color="primary" sx={{ mb: 1 }}>
                    {call.title}
                  </Typography>
                  <ReadMore text={call.description} limit={150} />
                  <Box mt="auto" pt={2} textAlign="center">
                    <Button
                      variant="contained"
                      color="primary"
                      href={call.applyLink}
                      target="_blank"
                    >
                      Apply Now
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Container>
  );
};

export default EventDetail;
