import React, { useState } from 'react';
import { Typography, Link } from '@mui/material';

const ReadMore = ({ text, limit = 150 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (text.length <= limit) {
    return <Typography variant="body2" paragraph>{text}</Typography>;
  }

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  return (
    <Typography variant="body2" paragraph>
      {isExpanded ? text : `${text.substring(0, limit)}... `}
      <Link
        component="button"
        variant="body2"
        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={toggleExpand}
      >
        {isExpanded ? 'Read Less' : 'Read More'}
      </Link>
    </Typography>
  );
};

export default ReadMore;
