// UEAustriaPage.js
import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  TextField,
  Paper,
  Link as MuiLink,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from 'emailjs-com';
// Replace with your actual images:
import ueLogo from '../assets/UE/UE_logo.jpg';
import ueHeroImage from '../assets/UE/HeaderBIld.jpg';
import foundationLogo1 from '../assets/UE/eypatlogo.png';
import foundationLogo2 from '../assets/UE/SchwarzkopfStiftungLogo.png';
import teamPhoto from '../assets/UE/teamPhoto.jpg'; // Replace with your real team photo

import FlipCoursesSection from '../components/FlipCoursesSection';

// Consistent heading style:
const headingStyle = {
  fontWeight: 600,
  lineHeight: 1.2,
  marginBottom: '1rem'
};

/** Restyled "Zur Unterstützung" Accordion **/
function SupportSection() {
  return (
    <Box sx={{ py: 4 }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            mb: 3,
            textAlign: 'center'
          }}
        >
          {/* Instead of letting the accordion label do all the styling, 
              we also add a leading heading for a more polished structure. 
          */}
          <Typography
            variant="h4"
            sx={{
              ...headingStyle,
              textAlign: 'center',
              fontWeight: 'bold',
              mb: 2
            }}
          >
            Zur Unterstützung
          </Typography>
          <Typography variant="body1" sx={{ color: '#555', maxWidth: 800, mx: 'auto' }}>
            Wie Sie Understanding Europe Austria unterstützen können – durch
            Engagement, Wissen oder andere Ressourcen. Klicken Sie auf die
            untenstehenden Themen, um mehr zu erfahren.
          </Typography>
        </Box>

        <Accordion
          disableGutters
          sx={{
            borderRadius: '8px',
            overflow: 'hidden',
            mb: 2,
            border: '1px solid #ddd',
            boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
            '&:before': {
              display: 'none'
            },
            '&:hover': {
              boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
            }
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ fontSize: '1.6rem', color: '#333' }} />
            }
            aria-controls="support-content"
            id="support-header"
            sx={{
              backgroundColor: '#fafafa',
              padding: '0 1.5rem',
              '& .MuiAccordionSummary-content': {
                my: 1
              }
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', mb: 0, color: '#333' }}
            >
              Was zeichnet Understanding Europe aus?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ px: 3, py: 2, backgroundColor: '#fff' }}>
            <Typography variant="body2" paragraph>
            Understanding Europe setzt auf Youth Empowerment und den Ansatz der Peer Education: Junge Menschen lernen gemeinsam und voneinander. Unsere Trainerinnen und Schülerinnen begegnen sich auf Augenhöhe und arbeiten interaktiv zu aktuellen Themen. Die Förderung politischen Engagements steht dabei im Mittelpunkt – sowohl für unsere freiwilligen Teammitglieder als auch für die Teilnehmenden in den Schulen. Darüber hinaus sind wir Teil eines europaweiten Netzwerks junger Freiwilliger in vielen Ländern Europas, was den Austausch über Grenzen hinweg fördert.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          disableGutters
          sx={{
            borderRadius: '8px',
            overflow: 'hidden',
            mb: 2,
            border: '1px solid #ddd',
            boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
            '&:before': {
              display: 'none'
            },
            '&:hover': {
              boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
            }
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ fontSize: '1.6rem', color: '#333' }} />
            }
            aria-controls="support-content2"
            id="support-header2"
            sx={{
              backgroundColor: '#fafafa',
              padding: '0 1.5rem',
              '& .MuiAccordionSummary-content': {
                my: 1
              }
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', mb: 0, color: '#333' }}
            >
              Wie Sie uns unterstützen können
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ px: 3, py: 2, backgroundColor: '#fff' }}>
            <Typography variant="body2" paragraph>
            Sie haben Kontakte zu Schulen oder möchten neue Zielgruppen erschließen? Dann helfen Sie uns gerne, unseren Wirkungskreis zu erweitern. Teilen Sie zudem Ihr Know-how aus dem Jugend-, Bildungs- oder Politikbereich, um gemeinsam noch mehr zu bewegen. Auch als Gastsprecher*in bei unseren jährlichen Fortbildungen können Sie uns bereichern und Ihre Expertise direkt an unsere Teammitglieder weitergeben. Ebenso freuen wir uns, wenn Sie im Gespräch mit uns Kooperationsmöglichkeiten ausloten – vom gemeinsamen Projekt bis hin zur langfristigen Partnerschaft.            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          disableGutters
          sx={{
            borderRadius: '8px',
            overflow: 'hidden',
            border: '1px solid #ddd',
            boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
            '&:before': {
              display: 'none'
            },
            '&:hover': {
              boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
            }
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ fontSize: '1.6rem', color: '#333' }} />
            }
            aria-controls="support-content3"
            id="support-header3"
            sx={{
              backgroundColor: '#fafafa',
              padding: '0 1.5rem',
              '& .MuiAccordionSummary-content': {
                my: 1
              }
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', mb: 0, color: '#333' }}
            >
Warum Ihre Unterstützung wichtig ist
</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ px: 3, py: 2, backgroundColor: '#fff' }}>
            <Typography variant="body2" paragraph>
            Wir stecken sehr viel ehrenamtliche Arbeit und Engagement in unsere Aktivitäten, damit junge Menschen Zugang zu vertiefenden Bildungsangeboten erhalten. Es wäre uns eine große Freude, wenn Sie unsere Vision teilen und uns auf unserem Weg unterstützen. Jede Form der Zusammenarbeit trägt dazu bei, die Ziele von Understanding Europe weiter voranzubringen.
</Typography>
          </AccordionDetails>
        </Accordion>
      </Container>
    </Box>
  );
}

export default function UEAustriaPage() {
  // For reCAPTCHA
  const [captchaValue, setCaptchaValue] = useState(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  // EmailJS config: fill in YOUR actual IDs
  const SERVICE_ID = 'service_pldnu2b';
  const TEMPLATE_ID = 'template_qq4f8mf';
  const USER_ID = 'Vv5gb9elfimoVviUI'; // or user token from EmailJS

   // Called when user completes reCAPTCHA:
   const handleRecaptchaChange = (token) => {
    setCaptchaValue(token);
  };

  // Submit form data via EmailJS
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaValue) {
      alert('Bitte füllen Sie reCAPTCHA aus!');
      return;
    }

    // Prepare the data to send to EmailJS
    const templateParams = {
      from_name: name,
      reply_to: email,
      message: message
      // You can add more fields if your template expects them
    };

    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Nachricht erfolgreich gesendet!');
        // Clear the form
        setName('');
        setEmail('');
        setMessage('');
        setCaptchaValue(null);
      })
      .catch((error) => {
        console.error('FAILED...', error);
        alert('Fehler beim Senden der Nachricht. Bitte erneut versuchen.');
      });
  };

  return (
    <Box sx={{ bgcolor: '#fff' }}>
      {/* Top Container: Logo, Intro Text & Photo */}
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Grid container spacing={4}>
          {/* Left Column: Logo + Intro Text */}
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 2 }}>
              <Box
                component="img"
                src={ueLogo}
                alt="Understanding Europe Austria logo"
                sx={{
                  maxWidth: '400px',
                  height: 'auto'
                }}
              />
            </Box>
            <Typography variant="body1" paragraph>
              <strong>Understanding Europe</strong> ist ein Bildungsnetzwerk für
              und von jungen Menschen in Europa. Wir engagieren uns für die
              Förderung von <strong>Demokratie, Diversität</strong> und{' '}
              <strong>politische Partizipation</strong>.
            </Typography>
            <Typography variant="body1" paragraph>
              Als Teil dieses europäischen Bildungsnetzwerks bieten wir als
              Understanding Europe Austria <strong>gratis Schulbesuche</strong>{' '}
              an Österreichs Schulen an, mit einem breiten Kursangebot zu den
              Themen <strong>Europa</strong>, <strong>Medien</strong>,{' '}
              <strong>Klimagerechtigkeit</strong> und{' '}
              <strong>politische Partizipation</strong>.
            </Typography>
          </Grid>

          {/* Right Column: Photo */}
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={ueHeroImage}
              alt="UE hero photo"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: 1,
                objectFit: 'cover'
              }}
            />
          </Grid>
        </Grid>
      </Container>

      {/* Next Section: Our Course Offerings */}
      <Box sx={{ bgcolor: '#f9f9f9', py: 4 }}>
        <Container maxWidth="lg">
          <Typography variant="h4" sx={headingStyle}>
            Unser Kursangebot
          </Typography>
          <Typography variant="body1" paragraph>
            Unser Kursangebot richtet sich primär an Schüler*innen der
            Oberstufe aller Schultypen. Unsere Kurse werden von je zwei
            ehrenamtlichen Trainer*innen unseres Teams gehalten und sorgfältig
            vorbereitet. Für die Kurse ist in der Regel eine Kursdauer von 2
            bzw. 4 Stunden vorgesehen. Unsere Kurse können wahlweise auf{' '}
            <strong>Deutsch</strong> oder <strong>Englisch</strong> angefragt
            werden und eignen sich somit auch sehr gut für den Englischunterricht.
          </Typography>
          <Typography variant="body1" paragraph>
            Pro Kursthema gibt es eine Auswahl an <strong>Workshops</strong>{' '}
            mit praktischen Aktivitäten wie Gruppenarbeiten oder
            Multimedia-Nutzung. Näheres dazu in unseren Kursbeschreibungen.
          </Typography>
          <Typography variant="body1" paragraph>
            Unsere <strong>Kurse sind gratis</strong>. Bei Interesse schicken
            Sie uns gerne eine unverbindliche Kursanfrage über unser{' '}
            <MuiLink
              href="#kontaktformular"
              sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
            >
              Kontaktformular
            </MuiLink>
            . Wir melden uns zeitnah per E-Mail. Für individuelle Absprachen zu
            Anpassungen bitten wir um Kontaktaufnahme per E-Mail. Wir sind
            darum bemüht, den Bedürfnissen der Schüler*innen gerecht zu werden.
          </Typography>
        </Container>
      </Box>

      {/* Flip-cards for the 4 course options */}
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h4" sx={headingStyle}>
          In unseren Kursen geht es um Europa, Medien,
          Klimagerechtigkeit und Politische Partizipation
        </Typography>
        <Typography variant="body1" paragraph>
          Unser Kurskonzept folgt dem Peer-Education-Ansatz: junge,
          qualifizierte Trainer*innen bringen Understanding Europe an Schulen
          oder außerschulische Bildungseinrichtungen. Dabei bieten wir digitale
          und analoge Formate für Schüler*innen und junge Menschen ab 14 Jahren an.
        </Typography>

        <FlipCoursesSection />
      </Container>

      {/* "Zum Mitmachen" Section */}
      <Box sx={{ py: 4 }}>
        <Container maxWidth="lg">
          <Typography variant="h4" sx={headingStyle}>
            Zum Mitmachen
          </Typography>
          <Typography variant="body1" paragraph>
            Bist Du selbst im <strong>Alter von 16 und 30 Jahren</strong> und
            möchtest Dich bei unserem Projekt ehrenamtlich engagieren? Lerne mehr
            über interaktives Arbeiten mit jungen Menschen, vertiefe Dein Wissen
            zu unseren Kursthemen und sammle praktische Erfahrung im Klassenraum
            sowie im Projektmanagement. Werde Teil eines europäischen
            Bildungsnetzwerks und nimm an Trainingsangeboten in Österreich und
            im Ausland teil. Bei Interesse melde Dich bei uns.
          </Typography>
        </Container>
      </Box>

      {/* Expandable "Zur Unterstützung" Section */}
      <SupportSection />

      {/* Team Section with large team photo */}
      <Box sx={{ bgcolor: '#f9f9f9', py: 4 }}>
        <Container maxWidth="lg">
          <Typography variant="h4" sx={headingStyle}>
            Unser Team von Understanding Europe Austria
          </Typography>
          <Box
            component="img"
            src={teamPhoto}
            alt="Unser Team"
            sx={{
              width: '100%',
              height: 'auto',
              borderRadius: '8px',
              mt: 2,
              boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
            }}
          />
        </Container>
      </Box>

         {/* CONTACT FORM with wave background (#f4bf01) */}
         <Box
        id="kontaktformular"
        sx={{
          position: 'relative',
          backgroundColor: '#f4bf01',
          pt: 8,
          pb: 8,
          overflow: 'hidden'
        }}
      >
        {/* TOP WAVE (from white down into #f4bf01) */}
        <Box
          component="svg"
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 'auto'
          }}
        >
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,224L30,213.3C60,203,120,181,180,170.7C240,160,300,160,360,186.7C420,213,480,267,540,266.7C600,267,660,213,720,186.7C780,160,840,160,900,149.3C960,139,1020,117,1080,117.3C1140,117,1200,139,1260,138.7C1320,139,1380,117,1410,106.7L1440,96L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0L0,0Z"
          />
        </Box>

        {/* BOTTOM WAVE (from #f4bf01 back to white) */}
        <Box
          component="svg"
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 'auto'
          }}
        >
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,192L80,197.3C160,203,320,213,480,202.7C640,192,800,160,960,165.3C1120,171,1280,213,1360,234.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          />
        </Box>

        <Container
          maxWidth="md"
          sx={{ position: 'relative', zIndex: 1, mt: 2, mb: 2 }}
        >
          <Typography variant="h4" sx={headingStyle}>
            Kontaktformular
          </Typography>
          <Typography variant="body1" paragraph>
            Sie möchten einen Kurs buchen oder haben Fragen?
            Schreiben Sie uns gerne eine Nachricht oder
            per E-Mail an{' '}
            <MuiLink
              href="mailto:understandingeurope@eyp.at"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              understandingeurope@eyp.at
            </MuiLink>.
          </Typography>

          <Paper
            sx={{
              p: 3,
              mt: 3,
              boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
              borderRadius: 2
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'medium', mb: 2 }}>
              Senden Sie uns eine Nachricht
            </Typography>

            <form onSubmit={handleSubmit}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                label="E-Mail"
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Ihre Nachricht"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                sx={{ mb: 2 }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />

              <Box sx={{ mb: 2 }}>
                <ReCAPTCHA
                  sitekey="6LcH5fcpAAAAAPuqGbytv_XfYXxutSogGDFivudQ" 
                  onChange={handleRecaptchaChange}
                />
              </Box>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!captchaValue}
              >
                Abschicken
              </Button>
            </form>
          </Paper>
        </Container>
      </Box>

      {/* Two-Logo row at bottom */}
      <Box sx={{ bgcolor: '#fff', py: 4 }}>
        <Container maxWidth="md">
          <Typography
            variant="h4"
            sx={{
              ...headingStyle,
              textAlign: 'center',
              marginBottom: '2rem'
            }}
          >
            Als Teil des Netzwerks von
          </Typography>
          <Grid container spacing={4} justifyContent="center" alignItems="center">
            {[foundationLogo1, foundationLogo2].map((logo, idx) => (
              <Grid
                item
                xs={12}
                sm={6}
                key={idx}
                sx={{ textAlign: 'center' }}
              >
                <Box
                  component="img"
                  src={logo}
                  alt={`Partner ${idx + 1}`}
                  sx={{
                    maxHeight: 120,
                    display: 'inline-block',
                    transition: 'filter 0.3s',
                    '&:hover': {
                      filter: 'none'
                    }
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}