// FlipCoursesSection.js
import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { styled } from '@mui/system';

// Import images
import climateCover from '../assets/UE/climate_cover.jpg';
import euCover from '../assets/UE/eu_cover.jpg';
import mediaCover from '../assets/UE/MediaCover.jpg';
import participationCover from '../assets/UE/participation_cover.jpg';

// -- Flipping container styles --
const FlipContainer = styled('div')({
  perspective: '1000px',
  width: '100%',
  height: '100%'
});

const FlipInner = styled('div')(({ isflipped }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  transformStyle: 'preserve-3d',
  transition: 'transform 0.6s',
  transform: isflipped === 'true' ? 'rotateY(180deg)' : 'none'
}));

const FlipSide = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backfaceVisibility: 'hidden',
  top: 0,
  left: 0
});

const FrontSide = styled(FlipSide)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start'
}));

const BackSide = styled(FlipSide)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(2),
  transform: 'rotateY(180deg)',
  overflowY: 'auto'
}));

// FlippableCard now takes an 'image' prop
function FlippableCard({ frontTitle, frontText, backTitle, backText, image }) {
  const [flipped, setFlipped] = useState(false);

  return (
    <FlipContainer>
      <FlipInner isflipped={flipped.toString()}>
        
        {/* Front of card */}
              {/* Front of card */}
        <FrontSide
        sx={{
            borderRadius: 5,
        }}>
          {/* Image at top, "zoomed out" */}
          <Box
            sx={{
              width: '100%',
              height: 200, // unify display
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              mb: 2,
              borderRadius: 1
            }}
          >
            <Box
              component="img"
              src={image}
              alt={frontTitle}
              sx={{
                height: '100%',
                width: 'auto',
                objectFit: 'contain',
              }}
            />
          </Box>

          <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
            {frontTitle}
          </Typography>
          <Typography variant="body2" sx={{ whiteSpace: 'pre-line', flex: 1 }}>
            {frontText}
          </Typography>

          <Box textAlign="right" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              size="small"
              onClick={() => setFlipped(true)}
            >
              Learn More
            </Button>
          </Box>
        </FrontSide>

        {/* Back of card */}
        <BackSide
        sx={{
            borderRadius: 5,
        }}>
          <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
            {backTitle}
          </Typography>
          <Typography variant="body2" sx={{ whiteSpace: 'pre-line', flex: 1 }}>
            {backText}
          </Typography>
          <Box textAlign="right" sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setFlipped(false)}
            >
              Back
            </Button>
          </Box>
        </BackSide>
      </FlipInner>
    </FlipContainer>
  );
}

// Data for the four flip cards, now includes 'image'
const flipCardsData = [
  {
    frontTitle: 'Europa',
    frontText: `Kurs: EU Crash Kurs
Dauer: 2 bzw. 4 Stunden
Sprachen: Deutsch, Englisch`,
    backTitle: 'Europa – Kompetenzcheck & Workshops',
    backText: `Kompetenzcheck:
• sich eigenständig neues Wissen über die EU in Teamarbeit erarbeiten
• ein besseres Verständnis dafür entwickeln, warum es die EU gibt, was sie tut und wofür sie zuständig ist
• die Rolle der EU im eigenen Leben erkennen (Reisefreiheit, Europawahlen)

Workshops:
1) Geschichte der EU:
   Es war einmal… Welche Ereignisse zählen zu den Meilensteinen der EU?
2) Institutionen der EU:
   Wer ist „die EU“? Unterschiede Europäischer Rat vs. Rat der EU?
3) Kompetenzen der EU:
   Wofür ist die EU zuständig? Beispiel: Umtausch von defekten Produkten, Zusammenarbeit mit Mitgliedstaaten usw.`,
    image: euCover
  },
  {
    frontTitle: 'Medien',
    frontText: `Kurs: Medien Crash Kurs
Dauer: 2 bzw. 4 Stunden
Sprachen: Deutsch, Englisch`,
    backTitle: 'Medien – Kompetenzcheck & Workshops',
    backText: `Kompetenzcheck:
• was Fake News sind und wie sie durch Kommentare/Reposts verstärkt werden
• welche Rolle Sprache bei der medialen Darstellung spielt
• Accounts/Informationen kritisch hinterfragen

Workshops:
1) Schneller als der Algorithmus:
   Wie bestimmen Algorithmen unseren Medienkonsum? Filter-Bubbles?
2) Diskriminierende Sprache:
   Wie kann Sprache diskriminierend sein? Einfluss auf Bilder von Personengruppen.
3) Bad News Game:
   Polarisierung in den Medien, was steckt hinter Fake News?`,
    image: mediaCover
  },
  {
    frontTitle: 'Klimagerechtigkeit',
    frontText: `Kurs: Klimagerechtigkeit Crash Kurs
Dauer: 2 bzw. 4 Stunden
Sprachen: Deutsch, Englisch`,
    backTitle: 'Klimagerechtigkeit – Kompetenzcheck & Workshops',
    backText: `Kompetenzcheck:
• Wissen über Klimawandel um Klimagerechtigkeit erweitern
• Klima(un-)gerechtigkeiten erkennen & kritisch reflektieren
• eigene Interessen & Privilegien bewusst machen

Workshops:
1) Be part of the solution, not pollution:
   Wie wir uns für Klimaschutz und Klimagerechtigkeit im urbanen Raum einsetzen.
2) Job Orientierung in Zeiten des Klimawandels:
   Wie junge Menschen mit ihrer Berufswahl helfen können, dem Klimawandel vorzubeugen.`,
    image: climateCover
  },
  {
    frontTitle: 'Politische Partizipation',
    frontText: `Kurs: Partizipation Crash Kurs
Dauer: 2 bzw. 4 Stunden
Sprachen: Deutsch, Englisch`,
    backTitle: 'Politische Partizipation – Kompetenzcheck & Workshops',
    backText: `Kompetenzcheck:
• eigene Standpunkte bewusster machen
• neue Wege der politischen Partizipation kennenlernen
• wie aktive Partizipation aussehen kann (Kampagnen)

Workshops:
1) Wie kann ich mich aktiver einbringen?
   Step by step zur eigenen Kampagne, klare Zielgruppe, passendes Medium.
2) Wie kann ich an der nationalen & europäischen Politik teilhaben?
   Neue Wege zu Partizipation, Stimme Gehör verschaffen, Wahlen, Demonstrationen, Petitionen etc.`,
    image: participationCover
  }
];

export default function FlipCoursesSection() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold' }}>
        Unsere 4 Kursoptionen
      </Typography>
      <Grid container spacing={3}>
        {flipCardsData.map((item, idx) => (
          <Grid item xs={12} sm={6} md={6} key={idx}>
            <Box sx={{ width: '100%', height: isSmall ? 'auto' : 370, boxShadow: 2, borderRadius: 5 }}>
              <FlippableCard
                frontTitle={item.frontTitle}
                frontText={item.frontText}
                backTitle={item.backTitle}
                backText={item.backText}
                image={item.image}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
