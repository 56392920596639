import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthProvider } from './authContext';
import AdminRoute from './components/AdminRoute';
import CookieConsent from './components/CookieConsent';
import CreateContactForm from './components/CreateContactForm';
import CreateEventForm from './components/events/CreateEvent';
import DashboardLayout from './components/DashboardLayout';
import ErrorPage from './components/ErrorPage';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import PrivateRoute from './components/PrivateRoute';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import AboutUsPage from './pages/AboutUsPage';
import ContactPage from './pages/ContactPage';
import EventDetailsPage from './pages/EventDetailsPage';
import EventsPage from './pages/EventsPage';
import HomePage from './pages/HomePage';
import Impressum from './pages/ImpressumPage';
import AdminDashboard from './pages/LoggedIn/Admin/Admin';
import AdminCalls from './pages/LoggedIn/Admin/AdminCalls';
import AdminContacts from './pages/LoggedIn/Admin/AdminContacts';
import AdminEvents from './pages/LoggedIn/Admin/AdminEvents';
import AdminNews from './pages/LoggedIn/Admin/AdminNews';
import AdminQuotes from './pages/LoggedIn/Admin/AdminQuotes';
import UserDashboard from './pages/LoggedIn/UserDashboard';
import MemberPage from './pages/MemberPage';
import NewsPage from './pages/NewsPage';
import PartnerPage from './pages/PartnerPage';
import PrivacyPolicy from './pages/PrivacyPolicyPage';
import SearchResultsPage from './pages/SearchResultsPage';
import RoleManagement from './pages/LoggedIn/Admin/AdminRoleManagement';
// import { useConsent } from './context/ConsentContext'; 
import ScrollToTop from './components/ScrollToTop';
import TicketSystemPage from './pages/LoggedIn/Admin/AdminTicketSystemPage';
import EditEvent from './components/events/EditEvent';
import ScrollToTopOnPageSwitch from './components/ScrollToTopOnPageSwitch';
import PageSelector from './components/editablePages/PageSelector';  // Import the PageSelector component
import AboutUsPageEditor from './components/editablePages/AboutUsPageEditor';  // Assuming you already have this component
import AdminPartnerPage from './pages/LoggedIn/Admin/AdminPartnerPage';  // Assuming you already have this component
import UEAustriaPage from './pages/UeAustria';


function App() {
  const [darkMode, setDarkMode] = useState(false);
  // const { openConsentDialog } = useConsent(); 

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#FFA500', // Set primary color to orange
      },
      secondary: {
        main: '#FF8C00', // Darker orange for secondary actions
      },
    },
    typography: {
      fontFamily: `'Source Sans Pro', sans-serif`,
      h1: {
        fontFamily: `'Source Sans Pro', sans-serif`,
      },
      h2: {
        fontFamily: `'Source Sans Pro', sans-serif`,
      },
      h3: {
        fontFamily: `'Source Sans Pro', sans-serif`,
      },
      h4: {
        fontFamily: `'Source Sans Pro', sans-serif`,
      },
      h5: {
        fontFamily: `'Source Sans Pro', sans-serif`,
      },
      h6: {
        fontFamily: `'Source Sans Pro', sans-serif`,
      },
      body1: {
        fontFamily: `'Source Sans Pro', sans-serif`,
      },
      body2: {
        fontFamily: `'Source Sans Pro', sans-serif`,
      },
      subtitle1: {
        fontFamily: `'Source Sans Pro', sans-serif`,
      },
      subtitle2: {
        fontFamily: `'Source Sans Pro', sans-serif`,
      },
      button: {
        fontFamily: `'Source Sans Pro', sans-serif`,
        textTransform: 'none', // Remove uppercase transformation on buttons
      },
      caption: {
        fontFamily: `'Source Sans Pro', sans-serif`,
      },
      overline: {
        fontFamily: `'Source Sans Pro', sans-serif`,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '20px',
          },
          containedPrimary: {
            backgroundColor: '#FFA500',
            '&:hover': {
              backgroundColor: '#FF8C00',
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: '#FFA500',
          },
        },
      },
    },
  });

  // useEffect(() => {
  //   openConsentDialog(); // Automatically open the consent dialog on app load
  // }, [openConsentDialog]);

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ScrollToTopOnPageSwitch />
        <Navbar darkMode={darkMode} setDarkMode={setDarkMode} />
        <Routes>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/events/:id" element={<EventDetailsPage />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/search" element={<SearchResultsPage />} />
          <Route path="/partner" element={<PartnerPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/membership" element={<MemberPage />} />
          <Route path="/ueaustria" element={<UEAustriaPage />} />
          {/* General user dashboard route */}
          <Route 
            path="/user-dashboard/*" 
            element={
              <PrivateRoute>
                <DashboardLayout><UserDashboard /></DashboardLayout>
              </PrivateRoute>
            } 
          />
          
          {/* Admin dashboard route */}
          <Route 
            path="/user-dashboard/admin" 
            element={
              <AdminRoute requiredPermission="manage_roles">
                <DashboardLayout><AdminDashboard /></DashboardLayout>
              </AdminRoute>
            } 
          />
          
          {/* Events management - requires "manage_events" permission */}
          <Route 
            path="/user-dashboard/admin/events" 
            element={
              <AdminRoute requiredPermission="manage_events">
                <DashboardLayout><AdminEvents /></DashboardLayout>
              </AdminRoute>
            } 
          />
          
          {/* News management - requires "manage_news" permission */}
          <Route 
            path="/user-dashboard/admin/news" 
            element={
              <AdminRoute requiredPermission="manage_news">
                <DashboardLayout><AdminNews /></DashboardLayout>
              </AdminRoute>
            } 
          />
          
          {/* Quotes management - requires "manage_quotes" permission */}
          <Route 
            path="/user-dashboard/admin/quotes" 
            element={
              <AdminRoute requiredPermission="manage_quotes">
                <DashboardLayout><AdminQuotes /></DashboardLayout>
              </AdminRoute>
            } 
          />
          
          {/* Calls management - requires "manage_calls" permission */}
          <Route 
            path="/user-dashboard/admin/calls" 
            element={
              <AdminRoute requiredPermission="manage_calls">
                <DashboardLayout><AdminCalls /></DashboardLayout>
              </AdminRoute>
            } 
          />
          
          {/* Contacts management - requires "manage_contacts" permission */}
          <Route 
            path="/user-dashboard/admin/contacts" 
            element={
              <AdminRoute requiredPermission="manage_contacts">
                <DashboardLayout><AdminContacts /></DashboardLayout>
              </AdminRoute>
            } 
          />

          {/* Parter management - requires "manage_events" permission */}
          <Route 
            path="/user-dashboard/admin/partner" 
            element={
              <AdminRoute requiredPermission="manage_events">
                <DashboardLayout><AdminPartnerPage /></DashboardLayout>
              </AdminRoute>
            } 
          />
          
          {/* Role management - requires "manage_roles" permission */}
          <Route 
            path="/user-dashboard/admin/role-management" 
            element={
              <AdminRoute requiredPermission="manage_roles">
                <DashboardLayout><RoleManagement /></DashboardLayout>
              </AdminRoute>
            } 
          />
          
          {/* Create event form - requires "manage_events" permission */}
          <Route 
            path="/create-event" 
            element={
              <AdminRoute requiredPermission="manage_events">
                <DashboardLayout><CreateEventForm /></DashboardLayout>
              </AdminRoute>
            } 
          />
          <Route 
            path="/edit-event/:id" 
            element={
              <AdminRoute requiredPermission="manage_events">
                <DashboardLayout><EditEvent /></DashboardLayout>
              </AdminRoute>
            } 
          />
          
          {/* Create contact form - requires "manage_contacts" permission */}
          <Route 
            path="/create-contacts" 
            element={
              <AdminRoute requiredPermission="manage_contacts">
                <DashboardLayout><CreateContactForm /></DashboardLayout>
              </AdminRoute>
            } 
          />
          
          
          {/* Ticket system management - requires "manage_tickets" permission */}
          <Route 
            path="/user-dashboard/admin/tickets" 
            element={
              <AdminRoute requiredPermission="manage_tickets">
                <DashboardLayout><TicketSystemPage /></DashboardLayout>
              </AdminRoute>
            } 
          />
          {/* Manage Pages selector route */}
          <Route 
            path="/user-dashboard/admin/manage-pages" 
            element={
              <AdminRoute requiredPermission="manage_content">
                <PageSelector />
              </AdminRoute>
            }
          />
          
          {/* Page editing routes */}
          <Route 
            path="/user-dashboard/admin/about-us" 
            element={
              <AdminRoute requiredPermission="manage_content">
                <AboutUsPageEditor />
              </AdminRoute>
            } 
          />

          <Route path="*" element={<ErrorPage message="This page does not exist or you do not have the right permissions." />} />
        </Routes>
        <ScrollToTop />
        <Footer />
        <CookieConsent />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
