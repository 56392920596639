// CallDialog.js
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel
} from '@mui/material';

/**
 * Props:
 *  - open: boolean
 *  - onClose: function
 *  - onSave: function(callData)
 *  - initialData: { title, description, endDate, applyLink, eventId, isDelegateCall, ... }
 *  - isEditing: boolean
 *  - events: array of {id, title} for linking
 */
export default function CallDialog({
  open,
  onClose,
  onSave,
  initialData,
  isEditing,
  events = []
}) {
  // We store the form fields in local state
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [endDate, setEndDate] = useState(''); // "YYYY-MM-DD"
  const [endTime, setEndTime] = useState(''); // "HH:MM"
  const [documentFile, setDocumentFile] = useState(null);
  const [link, setLink] = useState('');
  const [eventId, setEventId] = useState('');
  const [isDelegateCall, setIsDelegateCall] = useState(false);

  useEffect(() => {
    if (initialData) {
      // If editing, load existing values
      setTitle(initialData.title || '');
      setDescription(initialData.description || '');
      setLink(initialData.applyLink || '');
      setEventId(initialData.eventId || '');
      setIsDelegateCall(!!initialData.isDelegateCall);

      // Convert Firestore timestamp to local date/time
      if (initialData.endDate) {
        const dateObj = initialData.endDate.toDate();
        const yyyyMMdd = dateObj.toISOString().split('T')[0]; // "YYYY-MM-DD"
        const hh = String(dateObj.getHours()).padStart(2, '0');
        const mm = String(dateObj.getMinutes()).padStart(2, '0');
        setEndDate(yyyyMMdd);
        setEndTime(`${hh}:${mm}`);
      } else {
        setEndDate('');
        setEndTime('');
      }
      setDocumentFile(null);
    } else {
      // If creating a new call
      setTitle('');
      setDescription('');
      setLink('');
      setEventId('');
      setIsDelegateCall(false);
      setEndDate('');
      setEndTime('');
      setDocumentFile(null);
    }
  }, [initialData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!title || !description || !endDate || !endTime) {
      alert('Please fill out all required fields.');
      return;
    }

    // Combine endDate + endTime
    const combined = new Date(`${endDate}T${endTime}:00`);
    if (isNaN(combined.getTime())) {
      alert('Invalid date/time combination');
      return;
    }

    // Build the final data object to pass to onSave
    const callData = {
      title,
      description,
      endDate: combined,   // a JS date; parent will convert to Timestamp
      link,
      eventId,
      isDelegateCall,
      // The file is optional; parent might handle upload if needed
      document: documentFile 
    };

    onSave(callData);
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setDocumentFile(e.target.files[0]);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{isEditing ? 'Edit Call' : 'Create Call'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{ mb: 2 }}
          />

          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ mb: 2 }}
          />

          {/* Linked Event Dropdown */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="event-label">Linked Event</InputLabel>
            <Select
              labelId="event-label"
              label="Linked Event"
              value={eventId}
              onChange={(e) => setEventId(e.target.value)}
            >
              <MenuItem value="">None</MenuItem>
              {events.map((evt) => (
                <MenuItem key={evt.id} value={evt.id}>
                  {evt.title || evt.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Delegate Call Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                checked={isDelegateCall}
                onChange={(e) => setIsDelegateCall(e.target.checked)}
              />
            }
            label="Is this a Delegate Call?"
            sx={{ mb: 2 }}
          />

          {/* End Date + End Time */}
          <Box display="flex" gap={2} sx={{ mb: 2 }}>
            <TextField
              label="End Date"
              type="date"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <TextField
              label="End Time"
              type="time"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </Box>

          {/* Document Upload + Link */}
          <Box mb={2}>
            <Button variant="outlined" component="label" sx={{ mr: 2 }}>
              Upload Document
              <input
                type="file"
                accept=".pdf,.doc,.docx"
                hidden
                onChange={handleFileChange}
              />
            </Button>
            {documentFile && (
              <Typography variant="body1">{documentFile.name}</Typography>
            )}
            <TextField
              label="Or Add Link"
              variant="outlined"
              fullWidth
              value={link}
              onChange={(e) => setLink(e.target.value)}
              sx={{ mt: 2 }}
            />
          </Box>

          <DialogActions>
            <Button onClick={onClose} color="inherit">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {isEditing ? 'Save Changes' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
